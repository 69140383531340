
import AuthHeader from './AuthHeader'
import AnonHeader from './AnonHeader'
import { useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { getUser } from '@utility/UserHelper'

const Header = () => {
    const user = getUser()
    const location = useLocation()
    return (
        <Box position="fixed" backdropFilter="saturate(180%) blur(5px)" zIndex={200} w="100%">
        {user?.id ? (
            <AuthHeader user={user}/>
        ) : (
            <AnonHeader route={location.pathname}/>
        )}
        </Box>
    )
}

export default Header