import { defineStyle,  defineStyleConfig } from '@chakra-ui/react'

const brandPrimary = defineStyle({
    background: '#25D366',
    color: 'white',
    px: '4',
    _dark: {
        background: '#25D366',
        color: 'white',
        _hover: {
            bg: 'green.500',
            border: "none"
        }
    },
    _light: {
        background: '#25D366',
        color: 'white',
        _hover: {
            bg: 'green.500',
            border: "none"
        }
    },
})

const brandLight = defineStyle({
    color: '#25D366',
    px: '4',
    _dark: {
        border: "1px solid #25D366",
        color: '#25D366',
        _hover: {
            color: 'green.500',
            border: "2px solid #25D366",
        }
    },
    _light: {
        border: "1px solid #25D366",
        color: '#25D366',
        _hover: {
            color: 'green.500',
            border: "2px solid #25D366",
        }
    },
})
export const buttonTheme = defineStyleConfig({
    variants: { brandPrimary, brandLight },
})