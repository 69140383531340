import { Card, CardHeader, CardBody, CardFooter, Heading, Text, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import ExternalLink from '@components/routes/ExternalLink'


const TemplateCard = ({ header, description, redirectTo}) => {

    return (
        <Card>
            <CardHeader>
            <Heading size='md'> {header}</Heading>
            </CardHeader>
            <CardBody>
            <Text>{description}</Text>
            </CardBody>
            <CardFooter>
                <ExternalLink redirectTo={`${redirectTo}/edit`}>
                    <Button  variant="brandPrimary"  size="lg" mr={2}>View</Button>
                </ExternalLink>  
                <ExternalLink redirectTo={`${redirectTo}/copy`}>
                    <Button  variant="brandPrimary"  size="lg">Copy</Button>
                </ExternalLink>  
            </CardFooter>
        </Card>
    )

}

TemplateCard.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
};
export default TemplateCard