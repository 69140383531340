import {  Table, Thead, Tbody, Tr, Th, Td,  Flex, chakra} from "@chakra-ui/react"
import {  TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel
} from "@tanstack/react-table"

import { useState,  useEffect, useCallback } from "react"
import Pagination from "./Pagination"
import BulkActions from "./BulkActions"
import TableSearch from "./TableSearch"
import {
  rankItem
} from '@tanstack/match-sorter-utils'

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

import PropTypes from 'prop-types';

const DataTable  = ({tableQuery, pagination, columns, bulkActions, handleGroupAction,  multiSelect, onPageChange, hiddenColumns, rowClick}) => {
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnVisibility, setColumnVisibility] = useState({})

  const table = useReactTable({
    data: tableQuery.data?.rows ?? [],
    columns,
    pageCount: tableQuery.data?.pageCount ?? -1,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    enableRowSelection: multiSelect,
    state: {
      rowSelection,
      pagination,
      columnVisibility,
      sorting,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,  
    globalFilterFn: fuzzyFilter,  
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    initialState: { columnVisibility: hiddenColumns },
    debugTable: true
  })

  const handleRowClick = (event, row) => { 
    if (rowClick) {
      rowClick(event, row)
    } 
  }

  const onPaginationChange = useCallback((type, value) => {
    if (type === 'next') {
      onPageChange({pageIndex: pagination.pageIndex + 1, pageSize: pagination.pageSize})
    } else if (type === 'prev') {
      onPageChange({pageIndex: pagination.pageIndex - 1, pageSize: pagination.pageSize})
    } else if (type === 'jumpto') {
      console.log(value)
      onPageChange({pageIndex: value, pageSize: pagination.pageSize})
    } else if (type === 'pageSize') {
      onPageChange({pageIndex: pagination.pageIndex, pageSize: value})
    } else if (type === 'first') {
      onPageChange({pageIndex: 0, pageSize: pagination.pageSize})
    } else if (type === 'last') {
      onPageChange({pageIndex: table.getPageCount() - 1, pageSize: pagination.pageSize})
    }
  }, [onPageChange, pagination.pageIndex, pagination.pageSize, table])

  const handleAction =  async (event, action) => {
    console.log(action)
    event.preventDefault()
    const records = []
    if (multiSelect) {
      const rows = table.getSelectedRowModel().flatRows
      rows.forEach((row) => {
        records.push(row.original)
      })
      handleGroupAction(action, records)
    } else {
      handleGroupAction(action)
    }
  }

  useEffect(() => {
    table.setPagination(pagination)
    setColumnVisibility(hiddenColumns)
  }, [hiddenColumns, pagination, table])

  return (
    <>
    <Flex flex="2" p={4} flexDirection="row">
    {pagination && (  
    <TableSearch
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          placeholder="Search all columns..."
    />
    )}
    {bulkActions && (
      <BulkActions bulkActions={bulkActions} handleBulkAction={handleAction} enabled={rowSelection}/> 
    )}
    </Flex>
    {pagination && (
    <Pagination 
      pageSize={pagination.pageSize}
      pageIndex={pagination.pageIndex} 
      pageCount={table.getPageCount()} 
      hasPrevPage={table.getCanPreviousPage()}
      hasNextPage={table.getCanNextPage()}
      onPageChange={onPaginationChange}
    />
    )}
    <Table>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta = header.column.columnDef.meta
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}

                  <chakra.span pl="4">
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === "desc" ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id} onClick={(e) =>  handleRowClick(e, row)}>
            {row.getVisibleCells().map((cell) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta = cell.column.columnDef.meta
              return (
                <Td key={cell.id} isNumeric={meta?.isNumeric}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              )
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
    {pagination && (
    <Pagination 
      pageSize={pagination.pageSize}
      pageIndex={pagination.pageIndex} 
      pageCount={table.getPageCount()} 
      hasPrevPage={table.getCanPreviousPage()}
      hasNextPage={table.getCanNextPage()}
      onPageChange={onPaginationChange}
    />
    )}
    </>
  )
}

DataTable.propTypes = {
  tableQuery: PropTypes.object.isRequired,
  pagination: PropTypes.object,
  columns: PropTypes.array.isRequired,
  bulkActions: PropTypes.array,
  handleGroupAction: PropTypes.func,
  multiSelect: PropTypes.bool,
  onPageChange: PropTypes.func,
  hiddenColumns: PropTypes.object,
  rowClick: PropTypes.func
};

export default DataTable