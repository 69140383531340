import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import { useEffect, useState} from "react"
import PropTypes from 'prop-types';


const TableSearch = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }) => {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
        onChange(value)
        }, debounce)
        return () => clearTimeout(timeout)
    }, [debounce, onChange, value])

    return (
        <InputGroup pr={2}>
          <InputLeftElement pointerEvents='none'>
          <SearchIcon color='gray.300' />
        </InputLeftElement>
        <Input type='text' 
          size='lg'
          placeholder='Search all rows' 
          value={value}
          onChange={e => setValue(e.target.value)} 
          {...props}/>
      </InputGroup>  
    )
}

TableSearch.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    debounce: PropTypes.number
}

export default TableSearch