// ** Router imports
import { useRoutes } from 'react-router-dom'

// ** GetRoutes
import { Routes } from './routes'


const Router = () => {
  const routes = useRoutes([...Routes])
  return routes
}

export default Router