export function getUser() {
    const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX
    let user = localStorage.getItem(`${cookiePrefix}_user`)
  
    if (user) {
      user = JSON.parse(user)
      return {
        ...user
      }
    }
    return {}
  }