import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react'

const About = () => {
  return (
    <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center">
    <Stack minH={'100vh'} maxW="80%"  >
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
      <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
      <Heading color={'green.400'} mt={{ base: '2', lg: '0' }} fontSize="5xl" mb={2}>
        About us
      </Heading>
      <Text fontSize='2xl' >
            CANO PARTNERS <br/>
            WhatsApp Automation Solutions 
      </Text>
      <Box >  
        <Heading color={'green.400'} mt={{ base: '2', lg: '0' }} fontSize="3xl" mb={4}>
        Phone No: 
        </Heading>
        <Text mb={4} fontSize='xl'>
        +918237098983
        </Text>
        <Heading color={'green.400'} mt={{ base: '2', lg: '0' }} fontSize="3xl" mb={4}>
        Office Address: 
        </Heading>
        <Text mb={4} fontSize='xl'>
        FL­C­901 , The Latitude, New Heaven Park , Kondhwa , SN­18/6 ,
        NIBM Road Kondhwa , PUNE , Pune City , Pune , Maharashtra , 411048
        </Text>
      </Box>
      </Stack>   
      </Flex>  
    </Stack>
    </Flex>
  )
}

export default About
