
'use client'

import {
  Flex,
  Stack
} from '@chakra-ui/react'
import Nav from './Nav'
import DarkMode from './DarkMode'
import BrandLogo from './Logo'
import PropTypes from 'prop-types';

const AuthHeader = ({ user }) => {
  return (
    <Flex h={16} alignItems={'center'} justifyContent={'space-between'} p={4}>
        <BrandLogo />
        <Flex alignItems={'center'}>
        <Stack direction={'row'} spacing={7}>
            <DarkMode/>
            <Nav user={user}/>
        </Stack>
        </Flex>
    </Flex>
  )
}

AuthHeader.propTypes = {
  user: PropTypes.object.isRequired,
};

export default AuthHeader
