import { Stack, useCheckboxGroup } from "@chakra-ui/react"
import CustomCheckbox from './CustomCheckbox'
import PropType from 'prop-types'
import { useEffect } from "react"

const GroupCheckbox = ({items, defaults, setValue}) => {
    const { value, getCheckboxProps } = useCheckboxGroup({
        defaultValue: defaults,
    })

    useEffect(() => {
        console.log(value)
        setValue(value)
    }, [setValue, value])
    return (
      <Stack>
        {items.map((item) => (
          <CustomCheckbox key={item.event_id}  {...getCheckboxProps({ icon: item.event_type.includes("summary") ? "👁️":"🕒" ,value: item.event_id, label:`${item.event_type} - ${item.event_time}` })} />
        ))}
      </Stack>
    )
}

GroupCheckbox.propTypes = {
    items: PropType.array.isRequired,
    defaults: PropType.array.isRequired,
    setValue: PropType.func.isRequired
}

export default GroupCheckbox