import { createColumnHelper } from "@tanstack/react-table"
import { Divider, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, useDisclosure, useToast } from "@chakra-ui/react"
import { useSkeletonLoader } from "@hooks/useSkeletonLoader"
import { MdMoreVert, MdDelete, MdEdit, MdAdd } from "react-icons/md"
import { useCallback, useState } from "react"
import { useCalendar  } from '@hooks/useCalendar'
import DataTable from "@components/table/DataTable"
import EditCalendarEventModal from "./EditCalendarEventModal"
import AddCalendarEventModal from "./AddCalendarEventModal"
import { useQueryClient } from '@tanstack/react-query';
import PropType from 'prop-types'

const menuOptions =  [
    { label: 'Delete', icon: <MdDelete/>, action: 'delete'},
    { label: 'Edit', icon: <MdEdit/>, action: 'edit'}
]

const groupActions =  [
    { label: 'Delete', icon: <MdDelete/>, action: 'delete'},
    { label: 'Add', icon: <MdAdd/>, action: 'add'},
]



const CalendarEventsListing= ({calendarQuery, refresh, pagination, setPagination}) => {
    const queryClient = useQueryClient()
    const { onClose: onAddClose, onOpen: onAddOpen, isOpen: isAddOpen } = useDisclosure()
    const { onClose: onUpdateClose, onOpen: onUpdateOpen, isOpen: isUpdateOpen } = useDisclosure()
    const [calendarEvent, setCalendarEvent] = useState({})
    
   
    const toast = useToast()
    const calendar = useCalendar(toast)

    const onPageChange = useCallback((pageState) => {
        setPagination(pageState)
    }, [setPagination])

    const handleAction = async (event, row, action) => {
        event.preventDefault()
        if (action === 'edit') {
            setCalendarEvent(row.original)
            onUpdateOpen()
        } else if (action === 'delete') {
            try {
                const response = await calendar.deleteCalendarEvent({event_id: row.original.event_id})
                console.log(response)
                queryClient.invalidateQueries({ queryKey: ['org_calendar',  row.original.org_id] })
                queryClient.invalidateQueries({ queryKey: ['org_team', row.original.org_id] })
                refresh()
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleBulkAction = async (action, records) => {
        console.log(records)
        console.log(action)
        if (action === 'add') {
            onAddOpen()
        } else if(action === 'delete') {
            records.forEach((row) => {
                console.log(row)
            })
        }
    }
       
    const renderActions = (row, actions) => {
        const actionButtons = []
        actions.forEach((action, index) => {
            const isDisabled = !!(action.action === 'add')
            actionButtons.push(<MenuItem isDisabled={isDisabled} key={index} icon={action.icon} onClick={(event) => handleAction(event, row, action.action)}>{action.label}</MenuItem>)
        })
        return actionButtons
    }  

    const onEvent = async (data) => {
        if (data?.event_id) {
            try {
                const response = await calendar.updateCalendarEvent(data)
                console.log(response)
                onUpdateClose()
            }   catch (error) {
                console.log(error)
            }
        } else {
            try {
                const response = await calendar.addCalendarEvent(data)
                console.log(response)
                onAddClose()
            }   catch (error) {
                console.log(error)
            }
        }
        queryClient.invalidateQueries({ queryKey: ['org_calendar',  data?.org_id] })
        queryClient.invalidateQueries({ queryKey: ['org_team',  data?.org_id] })
        refresh()
  }

    const columnHelper = createColumnHelper()
    const columns = [
        {
            id: 'row_id',
            cell: (info) => 
                info.row.original.event_type.includes("summary") ? "👁️" : "🕒",
        },
        columnHelper.accessor("event_id", {
            header: 'ID',
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("event_type", {
            header: 'Event',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.accessor("event_category", {
            header: 'Category',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.accessor("event_time", {
            header: 'Time',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.display({
            header: 'Actions',
            cell: props => 
            <Menu>
            <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<MdMoreVert />}
            variant='outline'
            />
            <MenuList>
            {renderActions(props.row, menuOptions)}
            </MenuList>  
            </Menu>
        })
    ]
    const { tableData, tableColumns } = useSkeletonLoader(columnHelper, columns, calendarQuery)
    return (
        <>
        <Heading p={4} as="h1" size="lg" mb="6">Events</Heading>
        <DataTable tableQuery={tableData} pagination={pagination} columns={tableColumns} multiSelect={false} onPageChange={onPageChange} bulkActions={groupActions} handleGroupAction={handleBulkAction} />
        <EditCalendarEventModal isOpen={isUpdateOpen} onClose={onUpdateClose} event={calendarEvent} onEvent={onEvent}/>
        <AddCalendarEventModal isOpen={isAddOpen} onClose={onAddClose} onEvent={onEvent}/>
        <Divider />
        </>
    )
}

CalendarEventsListing.propTypes = {
    calendarQuery: PropType.object,
    refresh: PropType.func,
    pagination: PropType.object,
    setPagination: PropType.func
}

export default CalendarEventsListing