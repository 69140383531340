
import axios from "axios";

//apply base url for axios
const API_URL = import.meta.env.VITE_APP_APISERVER

const axiosInstance = axios.create({
  baseURL: API_URL
})

axiosInstance.interceptors.request.use(config => {
  const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX
  const token = localStorage.getItem(`${cookiePrefix}_token`)
  if (config.headers.Authorization === null) {
    delete config.headers.Authorization
  } else if (token) {
    config.headers.Authorization = `Bearer ${token}`
  } else {
    // config.headers.Authorization = `Bearer ${ANON_AUTH}`
  }
  return config
})

axiosInstance.interceptors.response.use(
  response => {
    console.log(JSON.stringify(response)) // This is logged for sentry breadcrumbs
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export { axiosInstance };
