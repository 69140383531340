import { Box, Heading, Text, UnorderedList, ListItem, Container, useColorModeValue, Flex } from '@chakra-ui/react'
import dayjs from 'dayjs'

const Privacy = () => {
  return (
    <Flex width="100vw"  flexDirection={"column"} justifyContent="center" alignItems="center">
    <Container maxW="container.xl" >
        <Box py={{ base: '2', lg: '0' }} >
            <Heading  fontSize="5xl" mt={{ base: '2', lg: '0' }} >
            Privacy Policy
            </Heading>

            <Box color={useColorModeValue('rgb(28, 32, 57)', 'purple.50')}>
            <Text mt="4" >
                This Privacy Policy has been compiled to better serve those who are
                concerned with how their &apos;Personally identifiable information&apos; (PII)
                is being used online. PII, as used in information security, is
                information that can be used on its own or with other information to
                identify, contact, or locate a single person, or to identify an
                individual in context. Please read our privacy policy carefully to
                get a clear understanding of how we collect, use, protect or
                otherwise handle your Personally Identifiable Information in
                accordance with our website or mobile app.
            </Text>
            <Text>
                This Privacy Policy (“Policy”) describes the policies and procedures
                on the collection, use, disclosure and protection of your
                information when you use our website located attaskeye.com, or the
                TaskEye mobile application (collectively, “TaskEye Platform”) made
                available by Cano Partners., a private company
                established under the laws of India having its registered office at
                FL­C­901 , The Latitude, New Heaven Park , Kondhwa , SN­18/6 ,
                NIBM Road Kondhwa , PUNE , Pune City , Pune , Maharashtra , 411048
            </Text>
            <Text>
                Please read this Policy before using the TaskEye Platform or submitting
                any personal information to TaskEye. This Policy is a part of and
                incorporated within, and is to be read along with, the Terms &
                condition.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Your Consent
            </Heading>
            <Text mt="4" >
                By using the TaskEye Platform and the Services, you agree and consent
                to the collection, transfer, use, storage, disclosure and sharing of
                your information as described and collected by us in accordance with
                this Policy. If you do not agree with the Policy, please do not use
                or access the TaskEye Platform.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                What personal information do we collect from the people that visit
                our website or app?
            </Heading>
            <Text mt="4" >
                When registering on our site or app, purchasing a membership,or
                managing profile, as appropriate, you may be asked to enter your
                name, email address, mailing address, phone number, profile picture,
                or other details to help you with enhancing your experience,
                communicate with you on transaction details, providing you with
                updates and for analytics purposes.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Device Information
            </Heading>
            <Text mt="4" >
                In order to improve your app experience and lend stability to our
                services to you, we may collect information or employ third party
                plugins that collect information about the devices you use to access
                our Services, including the hardware models, operating systems and
                versions, software, file names and versions, preferred languages,
                unique device identifiers, advertising identifiers, serial numbers,
                device motion information, mobile network information, and phone
                state. The information collected thus will be disclosed to or
                collected directly by these plugins and may be used to improve the
                content and/or functionality of the services offered to you.
                Analytics companies may use mobile device IDs to track your usage of
                the TaskEye Platform;
            </Text>
            <UnorderedList spacing={3} mt="4" >
                <ListItem>
                    To personalise user&apos;s experience and to allow us to deliver the
                    type of content and product offerings in which you are most
                    interested.
                </ListItem>
                <ListItem>
                    To improve the restaurant details showcasing both restaurant
                    uploaded and user uploaded images enhancing the overall booking
                    experience.
                </ListItem>
                <ListItem>
                    To carry out our obligations arising from any contracts entered
                    into between you and us and to provide you with the relevant
                    information and services;
                </ListItem>
                <ListItem>
                    Users can upload restaurant images while sharing there reviews or
                    while visiting a restaurant detail page. This is an optional
                    activity and it helps other users and businesses to showcase the
                    details better and help improve the experience.
                </ListItem>
                <ListItem>
                    To improve TaskEye ecosystem (mobile app and website) in order to
                    better serve you with improve restaurant information, review with
                    images
                </ListItem>
                <ListItem>
                    User profiles can enhanced by users uploading their profile image.
                    This is to personalise the app experience
                </ListItem>
                <ListItem>
                    To allow us to better service you in responding to your customer
                    service requests.
                </ListItem>
                <ListItem>
                    To administer and enhance the security of our TaskEye Platform and
                    for internal operations, including troubleshooting, data analysis,
                    testing, research, statistical and survey purposes;
                </ListItem>
                <ListItem>
                    To help our partners serve you better and complete the service
                    based on the information provided to them like reviews, user
                    information.
                </ListItem>
                <ListItem>
                    To understand our users (what they do on our Services, what
                    features they like, how they use them, etc.), improve the content
                    and features of our Services (such as by personalizing content to
                    your interests), process and complete your transactions, make
                    special offers, provide customer support, process and respond to
                    your queries;
                </ListItem>
            </UnorderedList>
            <Heading  fontSize="2xl" mt="3" >
                Disclosure and Distribution of your information
            </Heading>
            <Text mt="4" >
                We may share your information that we collect for following
                purposes:
            </Text>
            <Text>
                With Service providers: We may share your information with our
                vendors, consultants, marketing partners, research firms and other
                service providers or business partners, such as Payment processing
                companies, to support our business. For example, your information
                may be shared with payment partner to process and collect payments.
                We also may use vendors for other projects, such as conducting
                surveys for us.
            </Text>
            <Text>
                For Internal Use: We may share your information with any present or
                future member of our TaskEye team for analytics, support or service
                fulfilment purposes.
            </Text>
            <Text>
                With Advertisers and advertising networks: We may work with third
                parties such as network advertisers to serve advertisements on the
                TaskEye Platform and on third-party websites or other media (e.g.,
                social networking platforms). These third parties may use cookies,
                JavaScript, gifs and other tracking technologies to measure the
                effectiveness of their ads and to personalize advertising content to
                you. For 3rd party advertisers you can opt-out of it and then the
                data will not be shared.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                 How do we protect visitor information?
            </Heading>
            <Text mt="4" >
                Our website is scanned on a regular basis for security deficiency
                and known vulnerabilities in order to make your visit to our site as
                safe as possible.
            </Text>
            <Text>
                Your personal information is contained behind secured networks and
                is only accessible by a limited number of persons who have special
                access rights to such systems, and are required to keep the
                information confidential.
            </Text>
            <Text>
                We implement a variety of security measures when a customer books a
                restaurant, submits, or accesses their information to maintain the
                safety of your personal information.
            </Text>
            <Text>
                All transactions are processed through a gateway provider and are
                not stored or processed on our servers.
            </Text>
            <Text>
                It is important for you to protect against unauthorised access to
                your password and to your computer. Be sure to sign off when you
                finish using a shared computer.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Do we use &apos;cookies&apos;?
            </Heading>
            <Text mt="4" >
                Yes. Cookies are small files that a site or its service provider
                transfers to your computer&apos;s hard drive through your Web browser (if
                you allow) that enables the site&apos;s or service provider&apos;s systems to
                recognize your browser and capture and remember certain information.
                For instance, we use cookies to help us remember and process the
                items in your shopping cart.
            </Text>
            <Text>
                They are also used to help us understand your preferences based on
                previous or current site activity, which enables us to provide you
                with improved services. We also use cookies to help us compile
                aggregate data about site traffic and site interaction so that we
                can offer better site experiences and tools in the future.
            </Text>
            <Text>
                We use cookies to:
            </Text>
            <UnorderedList spacing={3} mt="4" >
                <ListItem>
                Understand and save user&apos;s preferences for future visits.
                </ListItem>
                <ListItem>
                Keep track of advertisements.
                </ListItem>
                <ListItem>
                    Compile aggregate data about site traffic and site interactions in
                    order to offer better site experiences and tools in the future. We
                    may also use trusted third party services that track this
                    information on our behalf.&nbsp;
                </ListItem>
            </UnorderedList>  
            <Text>
                You can choose to have your computer warn you each time a cookie is
                being sent, or you can choose to turn off all cookies. You do this
                through your browser (like Internet Explorer) settings. Each browser
                is a little different, so look at your browser&apos;s Help menu to learn
                the correct way to modify your cookies.
            </Text>  
            <Text>
                If users disable cookies in their browser:
            </Text>
            <Text>
                If you disable cookies off, some features will be disabled. It will
                turn off some of the features that make your site experience more
                efficient and some of our services will not function properly.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Do we disclose the information we collect to Third Parties?
            </Heading>
            <Text mt="4" >
                We do not sell, trade, or otherwise transfer to outside parties your
                name, address, city, town, any form or online contact identifier
                email, name of chat account, or any personally identifiable
                information without your consent. In case, we get into a
                partnership, which requires us to share the information with the
                said third party, same will be communicated to the customer very
                clearly, at the time of collecting the information.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Third party links
            </Heading>
            <Text mt="4" >
                Occasionally, at our discretion, we may include or offer third party
                products or services on our website. These third party sites have
                separate and independent privacy policies. We, therefore, have no
                responsibility or liability for the content and activities of these
                linked sites. Nonetheless, we will never share your personal
                information and will always protect the integrity of our site and
                welcome any feedback about these sites.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Policy Related to Minors
            </Heading>
            <Text mt="4" >
               taskeye.com does not knowingly collect personal information from minors
                under the age of 18. Minors are not permitted to use thetaskeye.com
                website or services, and TaskEye requests that minors under the age of
                18 not submit any personal information to the website. Since
                information regarding minors under the age of 18 is not collected,
               taskeye.com does not knowingly distribute personal information
                regarding minors under the age of 18.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                How does our site handle do not track signals?
            </Heading>
            <Text mt="4" >
                We honor do not track signals and do not track, plant cookies, or
                use advertising when a Do Not Track (DNT) browser mechanism is in
                place.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
                Does our site allow third party behavioral tracking?
            </Heading>
            <Text mt="4" >
                It is also important to note that we allow third party behavioral
                tracking COPPA (Children Online Privacy Protection Act) When it
                comes to the collection of personal information from children under
                13, the Children&apos;s Online Privacy Protection Act (COPPA) puts
                parents in control. The Federal Trade Commission, the nation&apos;s
                consumer protection agency, enforces the COPPA Rule, which spells
                out what operators of websites and online services must do to
                protect children&apos;s privacy and safety online.
            </Text>
            <Text>We do not specifically market to children under 13.</Text>
            <Heading  fontSize="2xl" mt="3" >
                Fair Information Practices
            </Heading>
            <Text mt="4" >
                The Fair Information Practices Principles form the backbone of
                privacy law in the United States and the concepts they include have
                played a significant role in the development of data protection laws
                around the globe. Understanding the Fair Information Practice
                Principles and how they should be implemented is critical to comply
                with the various privacy laws that protect personal information.
            </Text>
            <Text>
            In order to be in line with Fair Information Practices we will take
            the following responsive action, should a data breach occur:
            </Text>
            <Text>
             We will notify the users via email Within 1 business day
            </Text>
            <Text>
                We also agree to the individual redress principle, which requires
                that individuals have a right to pursue legally enforceable rights
                against data collectors and processors who fail to adhere to the
                law. This principle requires not only that individuals have
                enforceable rights against data users, but also that individuals
                have recourse to courts or a government agency to investigate and/or
                prosecute non-compliance by data processors.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
            Can Spam Act
            </Heading>
            <Text mt="4" >
            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.
            </Text>
            <Text>We collect your email address in order to:</Text>
            <UnorderedList spacing={3} mt="4" >
                <ListItem>
                Send information, respond to inquiries, and/or other requests or
              questions.
                </ListItem>
                <ListItem>
                Market to our mailing list or continue to send emails to our
              clients after the original transaction has occurred to enhance
              their dining experience&nbsp;
                </ListItem>
            </UnorderedList>  
            <Text>To be in accordance with CANSPAM we agree to the following:</Text>
            <UnorderedList spacing={3} mt="4" >
                <ListItem>
                NOT use false, or misleading subjects or email addresses
                </ListItem>
                <ListItem>
                Identify the message as an advertisement in some reasonable
              way&nbsp;
                </ListItem>
                <ListItem>
                Include the physical address of our business or site
              headquarters&nbsp;
                </ListItem>
                <ListItem>
                Monitor third party email marketing services for compliance, if
              one is used.&nbsp;
                </ListItem>
                <ListItem>
                Honour opt-out/unsubscribe requests quickly&nbsp;
                </ListItem>
                <ListItem>
                Allow users to unsubscribe by using the link at the bottom of each
              email&nbsp;
                </ListItem>
            </UnorderedList> 
            <Text> If at any time you would like to unsubscribe from receiving future
            emails, you can email us. Follow the instructions at the bottom of
            each email. And we will promptly remove you from ALL correspondence.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
            International Data Transfers
            </Heading>
            <Text mt="4" >
            When TaskEye receive any user information outside of India, we comply
            with providing adequate protection for the transfer of personal
            information. TaskEye acts as a data processor for its users based
            outside of India by providing data hosting, maintenance, support,
            and troubleshooting of services, as well as other redundant back
            office support. With respect to such international data transfers,
            we implement standard contractual clauses and other mechanisms to
            protect such data.
            </Text>
            <Text>
            Individuals that use products or services offered by TaskEye may wish
            to raise any privacy questions directly, including filing any
            requests to access U.S. or European customer data or exercise other
            privacy rights. Any Updates on TaskEye services is communicated to
            users only if the individual has opted in to receive information.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
            What does my acceptance of the privacy statement mean?
            </Heading>
            <UnorderedList spacing={3} mt="4" >
                <ListItem>
                By using TaskEye services, you signify your acceptance of this
              Privacy Statement.
                </ListItem>
                <ListItem>
                We reserve the right, to modify this Privacy Statement at any
              time.
                </ListItem>
                <ListItem>
                We reserve the right to terminate your access or use of service
              without any prior notice or take any other legal action if anyone
              violates these terms.
                </ListItem>
                <ListItem>
                Right of admission to the website is reserved.
                </ListItem>
            </UnorderedList> 
            <Heading  fontSize="2xl" mt="3" >
            Privacy Policy Governance and Compliance
            </Heading>
            <Text mt="4" >
            Any changes to this Privacy policy is governed by Privacy policy
            change advisory committee, email: chief@TaskEye.ltd
            </Text>
            <Text>
            Any change in privacy laws and compliance is monitored by the
            Privacy CAC group. Privacy policy is updated in accordance and users
            are notified of changes. Users should visit our Privacy Policy page
            to view any changes or updates pertaining to user information
            changes or compliance changes incorporated to our Privacy policy.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
            Privacy Policy Training
            </Heading>
            <Text mt="4" >
            All TaskEye employees undergo Information and Data Security, Privacy
            Policy and Security compliance training as part of on-boarding
            process. This training is done on Annual basis to ensure program
            compliance. Privacy policy CAC is responsible for the training and
            conduct of this program.
            </Text>
            <Heading  fontSize="2xl" mt="3" >
            Grievance Officer
            </Heading>
            <Text mt="4" >
            Any complaints, abuse or concerns with regards to content and or
            comment or breach of the terms in policy shall be immediately
            informed to the designated Grievance Officer as mentioned below in
            writing or through email signed with the electronic signature :
             </Text>   
             <Text>
             CANO PARTNERS
             WhatsApp Automation Solutions 
             </Text>
             <Text>
             Phone No: +918237098983
             </Text>
             <Heading  fontSize="2xl" mt="3" >
              Contact Us
            </Heading>
            <Text mt="4" >
            If there are any questions regarding this privacy policy you may
            contact us using the information below:
            </Text>
            <Text>
            Office Address: FL­C­901 , The Latitude, New Heaven Park , Kondhwa , S
            </Text>
            <Text>
            Phone No: +918237098983
             </Text>
             <Text>
             Or email to info@taskeye.com
             </Text>
             <Heading  fontSize="2xl" mt="3" className="primary-text-700">
                Copyright Notice
            </Heading> 
            <Text mt="4" >
                Copyright © {dayjs().format('YYYY')}, taskeye.com (Cano Partners).{' '}
            </Text>
            <Text >
                All Rights Reserved. This disclaimer/ terms of service notification
                are subject to change without notice.
            </Text>
            </Box>
        </Box>
    </Container>  
    </Flex>  
  )
}

export default Privacy