import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Select,
  InputGroup,
  InputLeftElement
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import {getCountryTelCode}  from "./Countries";
import PropTypes from 'prop-types';
import { PhoneIcon} from '@chakra-ui/icons'


const PhoneNumberInput = ({
  size,
  value,
  country,
  options,
  onChange,
  placeholder,
  ...rest
}) => {
  let [number, setNumber] = useState(value || "");
  let [selectedCountry, setSelectedCountry] = useState(country || "IND");
  let [countryCode, setCountryCode] = useState(
    getCountryTelCode(country) || "+91"
  );

  const onCountryChange = e => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    setCountryCode(code);
    setSelectedCountry(value);
    onChange(`${code}${number}`);
  };

  const onPhoneNumberChange = e => {
    let value = e.target.value;
    setNumber(value);
    onChange(`${countryCode}`,`${value}`);
  };
  
  useEffect(() => { 
    if (value) {
      setNumber(value)
    }
    if (country) {
      setSelectedCountry(country)
      setCountryCode(getCountryTelCode(country))
    }
  }, [value, country]);
  return (
    <InputGroup size={size} {...rest}>
      <InputLeftElement width="4rem">
        <Select
          top="0"
          left="0"
          zIndex={1}
          bottom={0}
          opacity={0}
          height="100%"
          size={size}
          position="absolute"
          value={selectedCountry}
          onChange={onCountryChange}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Select>
        <Flex width="100%" alignItems="left">
          {selectedCountry ? (
            <Box mr="4px" width="50%" flex={1} p={2}>
              <Flag height="1rem" code={selectedCountry || ""} />
            </Box>
          ) : (
            <PhoneIcon boxSize={6} />
          )}
        </Flex>
      </InputLeftElement>
      <Input
        type="tel"
        pl="4rem"
        size={size}
        value={number}
        onChange={onPhoneNumberChange}
        placeholder={placeholder}
        
      />
    </InputGroup>
  );
}

PhoneNumberInput.defaultProps = {
  options: [],
  size: "md"
};

PhoneNumberInput.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string,
  country: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  register: PropTypes.func
};
export default PhoneNumberInput