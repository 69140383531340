import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    _light: {
      border: '1px solid',
      borderColor: 'gray.400',
      background: 'gray.100',
      _hover: {
        border: '2px solid',
        borderColor: 'gray.300'
      },
      color: 'gray.800'
    },
    _dark: {
      border: '1px solid',
      borderColor: 'gray.600',
      background: 'gray.200',
      _hover: {
        border: '2px solid',
        borderColor: 'gray.800'
      },
      color: 'gray.800'
    }
  }
})

export const inputTheme = defineMultiStyleConfig({ baseStyle })