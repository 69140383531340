import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    _light: {
        color: 'gray.700',
        bg: 'gray.50',
        borderColor: "gray.900",
        borderWidth: "1px",
        boxShadow: 'lg',
    },
    _dark: {
        color: 'gray.100',
        bg: 'gray.700',
        borderColor: "gray.700",
        borderWidth: "1px",
        boxShadow: 'lg',
    }
  }
})

export const cardTheme = defineMultiStyleConfig({ baseStyle })