import sc from "states-cities-db";

const COUNTRIES = sc.getCountries();

const getCountryTelCode = country =>
  country && COUNTRIES.find(({ iso }) => iso === country).prefix;

const getTelCodeCountry = country =>
  country && COUNTRIES.find(({ prefix }) => prefix === country).iso;


export  { COUNTRIES, getCountryTelCode, getTelCodeCountry };
