import { createColumnHelper } from "@tanstack/react-table"
import MultiSelect from "@components/table/MultiSelect"
import {  Center, Flex, Heading, IconButton, Menu, MenuButton, MenuItem, MenuList, Stack, useDisclosure, useToast, useBreakpointValue } from "@chakra-ui/react"
import { useSkeletonLoader } from "@hooks/useSkeletonLoader"
import { MdMoreVert, MdDelete, MdEdit, MdAdd, MdPeople, MdChat } from "react-icons/md"
import { useCallback, useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useOrg  } from '@hooks/useOrg'
import DataTable from "@components/table/DataTable"
import EditOrgModal from "./EditOrgModal"
import CreateOrgModal from "./CreateOrgModal"
import { useNavigate } from "react-router"

const menuOptions =  [
    { label: 'Delete', icon: <MdDelete/>, action: 'delete'},
    { label: 'Edit', icon: <MdEdit/>, action: 'edit'},
    { label: 'View Team', icon: <MdPeople/>, action: 'team'},
    { label: 'List Chatbots', icon: <MdChat/>, action: 'chatbots'},
]

const groupActions =  [
    { label: 'Add', icon: <MdAdd/>, action: 'add'},
]

const hiddenColumns = { id: false } 


const OrgListing = () => {
    const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX
    const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
    const navigate = useNavigate()
    const { onClose: onEditOrgClose, onOpen: onEditOrgOpen, isOpen: isEditOrgOpen } = useDisclosure()
    const { onClose: onAddOrgClose, onOpen: onAddOrgOpen, isOpen: isAddOrgOpen } = useDisclosure()

    const [orgDetails, setOrgDetails] = useState({})
    const [{ pageIndex, pageSize, enabled }, setPagination] =
    useState({
        pageIndex: 0,
        pageSize: 10,
        enabled: true
    })
    const fetchDataOptions = {
        pageIndex,
        pageSize
    }

    const toast = useToast()
    const org = useOrg(toast)

    const onPageChange = useCallback((pageState) => {
        setPagination(pageState)
    }, [])

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
            enabled,
        }),
        [pageIndex, pageSize, enabled]
    )

    const getOrgs = async (options) => {
        const response = await org.getOrgs({limit: options.pageSize, offset: options.pageIndex * options.pageSize})
        const members = response.items ? response.items : []
        const data = { rows: members, pageCount: Math.ceil(response.total / options.pageSize), pageIndex, pageSize}
        return data
    }


    const gotoTeamDashboard = async (event, row) => {
        event.preventDefault()
        try {
            const response = await org.getOrg(row.original.id)
            localStorage.setItem(`${cookiePrefix}_token`, response.api_key)
            localStorage.setItem(`${cookiePrefix}_user`, JSON.stringify(response))
            localStorage.setItem(`${cookiePrefix}_org`, row.original.id)
            navigate(`/orgs/${row.original.id}/team`)
        }catch(error) {
           console.log(error)
        }
    }

    const gotoChatbotDashboard = async (event, row) => {
        event.preventDefault()
        try {
            const response = await org.getOrg(row.original.id)
            localStorage.setItem(`${cookiePrefix}_token`, response.api_key)
            localStorage.setItem(`${cookiePrefix}_user`, JSON.stringify(response))
            navigate(`/orgs/${row.original.id}/chatbots`)
        }catch(error) {
           console.log(error)
        }
    }

    const dataQuery = useQuery({
        queryKey: ['org_listing', fetchDataOptions],
        queryFn: () =>  getOrgs(fetchDataOptions),
        keepPreviousData : true
    })

    const handleAction = async (event, row, action) => {
        event.preventDefault()
        if (action === 'edit') {
            setOrgDetails(row.original)
            onEditOrgOpen()
        } else if(action === 'delete') {
            try {
                await org.deleteOrg({id: row.original.id})
                dataQuery.refetch()
            }catch(error) {
                console.log(error)
            }
        } else if(action === 'team') {
            gotoTeamDashboard(event, row)
        } else if(action === 'chatbots') {
            gotoChatbotDashboard(event, row)
        }
    }

    const handleBulkAction = async (action, records) => {
        if ( action === 'add') {
            console.log(action)
            onAddOrgOpen()
        } else {
            records.forEach((row) => {
                console.log(row)
            })
            console.log(action)
        } 
    }

    const addOrg = async (data) => {
        try {
            await org.addOrg(data) 
        }catch(error ) {
            console.log(error)
        }  
        dataQuery.refetch() 
    }

    const updateOrg = async (data, orgId) => {
        try {
            data.id = orgId
            await org.updateOrg(data)
        }catch(error ) {
            console.log(error)
        }
        dataQuery.refetch()
    }
       
    const renderActions = (row, actions) => {
        const actionButtons = []
        actions.forEach((action, index) => {
            const isDisabled = !!(action.action === 'add')
            actionButtons.push(<MenuItem isDisabled={isDisabled} key={index} icon={action.icon} onClick={(event) => handleAction(event, row, action.action)}>{action.label}</MenuItem>)
        })
        return actionButtons
    }  

    const columnHelper = createColumnHelper()
    const columns = [
        {
            id: 'row_id',
            header: ({ table }) => (
            <MultiSelect
                {...{
                isChecked: table.getIsAllRowsSelected(),
                isIndeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler()
                }}
            />
            ),
            cell: ({ row }) => (
            <MultiSelect
                {...{
                isChecked: row.getIsSelected(),
                isDisabled: !row.getCanSelect(),
                isIndeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                value : row.getValue('id')
                }}
            />
            )
        },
        columnHelper.accessor("id", {
            header: 'ID',
            enableHiding: true,
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("name", {
            header: 'Name',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.accessor("phone_number", {
            header: 'Mobile',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.accessor("email", {
            header: 'Email',
            cell: (info) => info.getValue(),
            enableColumnFilter: true
        }),
        columnHelper.display({
            header: 'Actions',
            cell: props => 
            <Menu>
            <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<MdMoreVert />}
            variant='outline'
            />
            <MenuList>
            {renderActions(props.row, menuOptions)}
            </MenuList>  
            </Menu>
        })
    ]
    const { tableData, tableColumns } = useSkeletonLoader(columnHelper, columns, dataQuery)
    return (
        <Center  minHeight = "100vh" minWidth = "100vw">
            <Flex justifyContent="center" alignItems="center" flexDirection={flexDirection} wrap="wrap">
                <Stack mt={4} height={"100%"}>
                    <Heading p={4} as="h1" size="lg" mb="6">Orgs</Heading>
                    <DataTable tableQuery={tableData} pagination={pagination} columns={tableColumns} multiSelect={false} onPageChange={onPageChange} bulkActions={groupActions} handleGroupAction={handleBulkAction} hiddenColumns={hiddenColumns} />
                    <EditOrgModal isOpen={isEditOrgOpen} onClose={onEditOrgClose} onUpdate={updateOrg} org={orgDetails}/>
                    <CreateOrgModal isOpen={isAddOrgOpen} onClose={onAddOrgClose} onAdd={addOrg}/>
                </Stack>
            </Flex>
        </Center>  
    )
}

export default OrgListing