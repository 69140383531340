
import { extendTheme } from '@chakra-ui/react'
import { inputTheme } from '@components/theme/Input.jsx'
import { modalTheme } from '@components/theme/modal.jsx'
import { menuTheme } from '@components/theme/menu.jsx'
import { buttonTheme } from '@components/theme/button.jsx'
import { cardTheme } from '@components/theme/card'
import { checkboxTheme } from '@components/theme/checkbox'

const Theme = extendTheme({
      styles: {
         global: (props) => ({
            'html, body': {
               color: props.colorMode === 'dark' ? 'gray.200' : 'gray.700',
               bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
               lineHeight: 'tall'
            }
         })
     },
     config: { initialColorMode: 'light', useSystemColorMode: false},
     fonts: {
        heading: `'Heading Font Name', MYRIADPRO-BOLD`,
        body: `'Body Font Name', MYRIADPRO-REGULAR`
     },
     components: {
      Card: cardTheme,
      Modal: modalTheme,
      Checkbox: checkboxTheme,
      Form: {
         baseStyle: {
            fontStyle:"bold"
         }
      },
      Menu: menuTheme,
      Button: buttonTheme,
      Input: {
         ...inputTheme,
         sizes: {
            xs: {
               field: {
               borderRadius: 'none'
               }
            },
            sm: {
               field: {
               borderRadius: 'none'
               }
            },
            md: {
               field: {
               borderRadius: 'none'
               }
            },
            lg: {
               field: {
               borderRadius: 'none'
               }
            }
        }
      }
    }
})

export default Theme