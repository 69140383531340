import { useRestClient } from "./useRestClient"

export const useTeam = (toast) => {

    const restClient = useRestClient()
    const addTeamMember = async (params) => {
        return restClient.post('/api/v1/team', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const updateTeamMember = async (params) => {
        return restClient.patch('/api/v1/team', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const getTeamMembers = async (params) => {
        return restClient.get('/api/v1/team', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    const deleteTeamMember = async (params) => {
        return restClient.del('/api/v1/team', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })    
    }

    return {
        addTeamMember,
        updateTeamMember,
        getTeamMembers,
        deleteTeamMember
    }
}

