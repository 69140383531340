

import {
    Box
  } from '@chakra-ui/react'
import ReactLogo from '@assets/logo.svg?react'
import WebLink from '../routes/WebLink'

const BrandLogo = () => {
    return (
        <WebLink to='/'>
            <Box ><ReactLogo/></Box>
        </WebLink>
    )
}

export default BrandLogo