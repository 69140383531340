import { Box, chakra, Text, useCheckbox } from "@chakra-ui/react"
import PropType from 'prop-types'

const CustomCheckbox = (props) => {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
      useCheckbox(props)

    return (
      <chakra.label
        display='flex'
        flexDirection='row'
        alignItems='center'
        gridColumnGap={2}
        bg='green.50'
        border='1px solid'
        borderColor='#25D366'
        rounded='lg'
        px={3}
        py={1}
        cursor='pointer'
        {...htmlProps}
      >
        <input {...getInputProps()} hidden />
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          border='2px solid'
          borderColor='#25D366'
          w={4}
          h={4}
          {...getCheckboxProps()}
        >
          {state.isChecked && <Box w={2} h={2} bg='#25D366' />}
        </Box>
        <Text color='gray.700' {...getLabelProps()}>
         {props.icon} {props.label}
        </Text>
      </chakra.label>
    )
}

CustomCheckbox.propTypes = {
    value: PropType.number.isRequired,
    label: PropType.string.isRequired,
    icon: PropType.string.isRequired
}

export default CustomCheckbox