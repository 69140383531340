import { Button,  FormControl, FormErrorMessage, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import PhoneNumberInput from '@components/input/PhoneNumberInput';
import { COUNTRIES } from "@components/input/countries";
import { useState } from "react";
import PropTypes from 'prop-types';


const CreateOrgModal = ({ isOpen, onClose, onAdd }) => {

    const [number, setNumber] = useState("")
    const [telCode, setTelCode] = useState("")

    const countryOptions = COUNTRIES.map(({ name, iso }) => ({
        label: name,
        value: iso
    }));

    const onPhoneNumberChange = (telCode, number) => {
        console.log(`${telCode}${number}`)
        setNumber(number)
        setTelCode(telCode)
    }
    
    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: { errors, isSubmitting }
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone_number: "",
            department: "",
            calendar : [],
            events: [],
        },
    })

    const createOrg = async (data) => {
        data.phone_number = `${telCode}${number}`
        data.country_code = `${telCode}`
        if (!data.phone_number) {
            setError('phone_number', {
                type: 'manual',
                message: 'Phone number is required'
            })
        } else {
            await onAdd(data)
            onClose()
            clearErrors()
        }
    }

    return (
        <Modal closeOnOverlayClick={true} size={'xl'} onClose={onClose} isOpen={isOpen}  blockScrollOnMount={false}  scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{"Add org"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <form onSubmit={handleSubmit(createOrg)}>  
                <Stack  spacing={5} direction="column">
                    <Heading size='md'>Org details</Heading>
                    <FormControl  isInvalid={errors.name && true}>
                        <FormLabel htmlFor='name'>Name</FormLabel>
                        <Input id="name" 
                            name="name"
                            type='text'
                            size='lg'
                            bg={'gray.100'}
                            {...register('name', {
                                required: 'Name is required'
                            })}
                        />
                        {errors.name && (
                        <FormErrorMessage>
                        Please enter org name
                        </FormErrorMessage>
                        )}      
                    </FormControl>  
                    <FormControl  isInvalid={errors.phone_number && true}>
                        <FormLabel htmlFor='phone_number'>Phone number</FormLabel>
                        <PhoneNumberInput
                                name="phone_number"
                                id="phone_number"
                                value={number}
                                options={countryOptions}
                                onChange={(code, value) => onPhoneNumberChange(code, value)}      
                        />
                        {errors.phone_number && (
                        <FormErrorMessage>
                            Please enter a valid number
                        </FormErrorMessage>
                        )}      
                    </FormControl>
                    <FormControl  isInvalid={errors.email && true}>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <Input id="email" 
                            name="email"
                            type='text'
                            size='lg'
                            bg={'gray.100'}
                            {...register('email', {
                                required: 'Email is required'
                            })}
                        />
                        {errors.email && (
                        <FormErrorMessage>
                        Please enter a valid email
                        </FormErrorMessage>
                        )}      
                    </FormControl>  
                    <Button variant="brandPrimary"  size="lg" type="submit" isLoading={isSubmitting} loadingText="Creating org...">
                            Create org
                    </Button>
                    </Stack>
                  </form>  
                </ModalBody>
            </ModalContent>
        </Modal>  
    )
}

CreateOrgModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
}
export default CreateOrgModal