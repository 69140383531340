import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Flex, IconButton, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Show, Text, Tooltip } from "@chakra-ui/react"
import PropTypes from 'prop-types';

const Pagination = ({ pageIndex, pageCount, pageSize, hasPrevPage, hasNextPage, onPageChange }) => {

    return (
        <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              variant={"brandPrimary"}
              onClick={(e) => hasPrevPage ? onPageChange('first') : e.preventDefault()}
              isDisabled={!hasPrevPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              variant={"brandPrimary"}
              onClick={(e) => hasPrevPage ? onPageChange('prev') : e.preventDefault()}
              isDisabled={!hasPrevPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>
        <Show above='sm'>
        <Flex alignItems="center" >
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageCount}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageCount}
            isDisabled={pageCount <= 1}
            onChange={(value) => {
              const page = value ? value - 1 : 0
              onPageChange('jumpto', page)
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            isDisabled={pageCount <= 1}
            value={pageSize}
            onChange={(e) => {
                onPageChange('pageSize', Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
        </Show>        
        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              variant={"brandPrimary"}
              onClick={(e) => hasNextPage ? onPageChange('next') : e.preventDefault()}
              isDisabled={!hasNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              variant={"brandPrimary"}
              onClick={(e) => hasNextPage ? onPageChange('last') : e.preventDefault()}
              isDisabled={!hasNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
    )
}

Pagination.propTypes = {
    pageIndex: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    hasPrevPage: PropTypes.bool.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired
}

export default Pagination