import {
    getAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut
} from 'firebase/auth'


export const useFirebaseAuth = (toast) => {
       
    const signUp = async (email, password, displayName) => {
          const auth = getAuth()
          return createUserWithEmailAndPassword(auth, email, password)
            .then(userCredential => {
                updateProfile(userCredential.user, { displayName })
                sendEmailVerification(userCredential.user)
                return userCredential.user
            })
            .catch(error => {
                const errorMessage = error.message
                if (errorMessage.includes('auth/email-already-in-use')) {
                    return login(email, password)
                } else {
                    toast({ title: `${errorMessage}`, status: 'error', duration: 3000, isClosable: true })
                }
            })
    }

    const login = async (email, password) => {
       const auth = getAuth()
       return  signInWithEmailAndPassword(auth, email, password)
            .then(userCredential => {
                return userCredential.user
            })
            .catch(error => {
                const errorMessage = error.message
                toast({ title: `${errorMessage}`, status: 'error', duration: 3000, isClosable: true })
            })
    }

    const logout = async () => {
        const auth = getAuth()
        const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX

        return signOut(auth)
            .then(() => {
                localStorage.removeItem(`${cookiePrefix}_token`)
                localStorage.removeItem(`${cookiePrefix}_user`)
                localStorage.removeItem(`${cookiePrefix}_org`)
                return true
            })
            .catch(error => {
                const errorMessage = error.message
                toast({ title: `${errorMessage}`, status: 'error', duration: 3000, isClosable: true })
            })
    }

    const forgotPassword = async (email) => {
        const auth = getAuth()

        return sendPasswordResetEmail(auth, email)
            .then(() => {
                return true
            })
            .catch(error => {
                const errorMessage = error.message
                toast({ title: `${errorMessage}`, status: 'error', duration: 3000, isClosable: true })
            })
    }

    const getToken = async () => {
        const auth = getAuth()

       return onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken()
                    .then((idToken) => {
                       
                        return idToken
                    })
                    .catch((error) => {
                        const errorMessage = error.message
                        toast({ title: `${errorMessage}`, status: 'error', duration: 3000, isClosable: true })
                    })
            }
        })
    }


    return  { signUp, login, logout, forgotPassword, getToken }
}