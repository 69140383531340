import {  Checkbox } from "@chakra-ui/react"
import { useEffect, useRef } from "react"
import PropTypes from 'prop-types';


const MultiSelect = ({indeterminate, ...rest}) => {
    const ref = useRef(null)
  
    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate, rest.checked])
  
    return (
      <Checkbox colorScheme='pink'
        value={rest.value}
        ref={ref}
        {...rest}
      />
    )
  }

MultiSelect.propTypes = {
    indeterminate: PropTypes.bool
}

export default MultiSelect