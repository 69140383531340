import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    padding: 3, // change the padding of the control
    borderRadius: 0, // change the border radius of the control
  },
  container: {
    _light: {
        color: 'gray.700',
        bg: 'gray.50',
    },
    _dark: {
        color: 'gray.100',
        bg: 'gray.700',
    }
  }
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })