'use client'

import {
  Flex,
  Text,
  Heading,
  Stack,
  Box
} from '@chakra-ui/react'


const Home = () => {
 
  return (
    <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center">
    <Stack minH={'100vh'} maxW="80%"  >
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
      <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '5xl', md: '7xl' }}
            lineHeight={'110%'}>
            Automate your google spreadsheets <br />
            <Text as={'span'} color={'green.400'}>
             with whatsapp
            </Text>
          </Heading>
          <Text color={'gray.400'} fontSize={"xl"} flexWrap={"wrap"}>
            Track SOP checklists, attendance, form submissions and more with our excel to whatsapp bot.
            Convert your existing excel sheet to a whatsapp bot and see the magic happen.
          </Text>
          {/* <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            <Button
              colorScheme={'green'}
              bg={'green.400'}
              rounded={'full'}
              px={6}
              onClick={listSheets}
              _hover={{
                bg: 'green.500',
                border: "none"
                }}>
              Get Started
            </Button>
          </Stack> */}
        </Stack>
      </Flex>  
    </Stack>
    </Flex>
  )
}

export default Home