import { useRestClient } from "./useRestClient"

export const useOrg = (toast) => {

    const restClient = useRestClient()
    
    const addOrg = async (params) => {
       return restClient.post('/api/v1/orgs', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })       
    }

    const getOrgs = async (params) => {
        return restClient.get('/api/v1/orgs', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })     
    }

    const getOrg = async (id) => {
        return restClient.get('/api/v1/orgs/'+id)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })     
    }

    const updateOrg = async (params) => {
        return restClient.patch('/api/v1/orgs', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })     
    }

    const deleteOrg = async (params) => {
        return restClient.del('/api/v1/orgs', params)
            .then((response) => response)
            .catch((error) => error)
            .then((dataOrError) => {
                if (dataOrError.status === 'success') {
                    return dataOrError.data
                } else {
                    return restClient.handleError(dataOrError, toast)
                }
            })     
    }

    return {
        addOrg,
        getOrgs,
        updateOrg,
        deleteOrg,
        getOrg
    }
}

