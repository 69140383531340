import LoginCard from "@features/auth/Login";
import Home from "@features/home/Home";
import CreateChatbot from "@features/chatbots/CreateChatbot";
import AppLayout from "@components/layout/AppLayout";  
import Terms from "@features/company/Terms";
import About from "@features/company/About";
import Privacy from "@features/company/Privacy";
import ChatbotListing from "@features/chatbots/ChatbotListing";
import OrgDashboard from "@features/org/OrgDashboard";
import OrgListing from "@features/org/OrgListing";

const adminPath = import.meta.env.VITE_ADMIN_URL_PATH

const Routes = [
    {
        path: "/login",
        element: <LoginCard />,
    },
    {
      path: "/",
      element: <AppLayout><Home /></AppLayout>,
    },
    {
      path: "/terms",
      element: <AppLayout><Terms/></AppLayout>
    },
    {
      path: "/about",
      element: <AppLayout><About/></AppLayout>
    },
    {
      path: "/privacy",
      element: <AppLayout><Privacy/></AppLayout>
    },
    {
      path: adminPath+"/orgs",
      element: <AppLayout><OrgListing/></AppLayout>
    },
    {
      path: "/orgs/:orgId/team",
      element: <AppLayout><OrgDashboard/></AppLayout>
    },
    {
      path: "/orgs/:orgId/chatbots",
      element: <AppLayout><ChatbotListing/></AppLayout>
    },
    {
      path: "/orgs/:orgId/chatbot",
      element: <AppLayout><CreateChatbot /></AppLayout>
    },
];
export { Routes }