'use client'

import {
  Box,
  useColorModeValue
} from '@chakra-ui/react'
import Header from './Header'
import PropTypes from 'prop-types';

const AppLayout = ({ children }) => {
    return (
        <>
            <Header />
            <Box
                bg={useColorModeValue('gray.50', 'gray.800')}
                pt={16}
            >
                {/* <ReloadPrompt /> */}
                {children}
            </Box>
        </>
    )
}

AppLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppLayout