import {
    Avatar,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Center
  } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom'
import { useFirebaseAuth } from '@hooks/useFirebaseAuth';
import { useParams  } from 'react-router-dom';
import PropTypes from 'prop-types';

const Nav = ({ user }) => {
    const cookiePrefix = import.meta.env.VITE_COOKIE_PREFIX
    const { logout } = useFirebaseAuth()
    const navigate = useNavigate()
    let { orgId } = useParams();
    const onLogout = async () => {
        await logout()
        navigate('/')
    }

    const viewTeam = async () => { 
        const cookieOrgId = localStorage.getItem(`${cookiePrefix}_org`)
        if (orgId !== undefined ) {
           navigate(`/orgs/${orgId}/team`)
        } else if(cookieOrgId !== null && cookieOrgId !== undefined) {
              navigate(`/orgs/${cookieOrgId}/team`)
        } else {
            await logout()
            navigate('/')
        }
    }

    const viewChatbots = async () => { 
        const cookieOrgId = localStorage.getItem(`${cookiePrefix}_org`)
        if (orgId !== undefined) {
           navigate(`/orgs/${orgId}/chatbots`)
        } else if(cookieOrgId !== null && cookieOrgId !== undefined) {
              navigate(`/orgs/${cookieOrgId}/chatbots`)
        } else {
            await logout()
            navigate('/')
        }
    }

    return (
        <Menu>
            <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                size={'sm'}
                src={user?.logo}
                loading='lazy'
                />
            </MenuButton>
            <MenuList alignItems={'center'}>
                <br />
                <Center>
                <Avatar
                    size={'2xl'}
                    src={user?.logo}
                    loading='lazy'
                />
                </Center>
                <br />
                <Center>
                <p>{user?.name}</p>
                </Center>
                <br />
                <MenuDivider />
                <MenuItem onClick={viewTeam}>Team</MenuItem>
                <MenuItem onClick={viewChatbots}>Chatbots</MenuItem>
                <MenuItem onClick={onLogout}>Logout</MenuItem>
            </MenuList>
        </Menu>
    )
}

Nav.propTypes = {
    user: PropTypes.object.isRequired,
};
export default Nav