import { Center, Flex, Spinner, Stack, useBreakpointValue, useToast } from "@chakra-ui/react";
import TeamListing from "@features/team/TeamListing";
import CalendarEventsListing from "@features/calendar/CalendarEventsListing";
import { useQuery } from "@tanstack/react-query"
import { useTeam  } from '@hooks/useTeam'
import { useCalendar } from '@hooks/useCalendar'
import { useMemo, useState } from "react";
import { useParams }    from "react-router-dom";

const OrgDashboard = () => {
    const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
    const toast = useToast()
    const team = useTeam(toast)
    const calendar = useCalendar()
    const { orgId } = useParams()

    const [{ pageIndex, pageSize, enabled }, setPagination] =
    useState({
        pageIndex: 0,
        pageSize: 30,
        enabled: false
    })

    const fetchDataOptions = {
        pageIndex,
        pageSize
    }

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
            enabled,
        }),
        [pageIndex, pageSize, enabled]
    )

    const getTeamMembers = async (options) => {
        const response = await team.getTeamMembers({limit: options.pageSize, offset: options.pageIndex * options.pageSize})
        const members = response.items ? response.items : []
        const data = { rows: members, pageCount: Math.ceil(response.total / options.pageSize), pageIndex, pageSize}
        return data
    }
    const dataQuery = useQuery({
        queryKey: ['org_team',  orgId],
        queryFn: () =>  getTeamMembers(fetchDataOptions),
        enabled: !!orgId
    })

    const getCalendarEvents = async (options) => {
        const response = await calendar.getCalendarEvents({limit: options.pageSize, offset: options.pageIndex * options.pageSize})
        const members = response.items ? response.items : []
        const data = { rows: members, pageCount: Math.ceil(response.total / options.pageSize), pageIndex, pageSize}
        return data
    }

    const calendarQuery = useQuery({
        queryKey: ['org_calendar',  orgId],
        queryFn: () =>  getCalendarEvents(fetchDataOptions),
        enabled: !!orgId
    })

    const refreshDashboard = async () => {
        await dataQuery.refetch()
        await calendarQuery.refetch()
    }

    if (dataQuery.isLoading) return <Spinner/>;
    if (calendarQuery.isLoading) return <Spinner/>;
    return (
        <Center  minHeight = "100vh" minWidth = "100vw">
            <Flex justifyContent="center" alignItems="center" flexDirection={flexDirection} wrap="wrap">
                <Stack mt={4} height={"100%"}>
                    <TeamListing teamQuery={dataQuery} calendarQuery={calendarQuery} refresh={refreshDashboard} pagination={pagination} setPagination={setPagination}/>
                    <CalendarEventsListing calendarQuery={calendarQuery} refresh={refreshDashboard} pagination={pagination} setPagination={setPagination}/>
                </Stack>
            </Flex>
        </Center>        
    )
}

export default OrgDashboard;