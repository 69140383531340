import { Box, Heading, Text, UnorderedList, ListItem, Container, useColorModeValue, Flex } from '@chakra-ui/react'
import dayjs from 'dayjs'

const Terms = () => {
  return (
    <Flex width="100vw"  flexDirection={"column"} justifyContent="center" alignItems="center">
    <Container maxW="container.xl" >
      <Box py={{ base: '2', lg: '0' }} >
        <Heading  fontSize="5xl" mt={{ base: '2', lg: '0' }} >
          Terms of Service
        </Heading>

        <Box color={useColorModeValue('rgb(28, 32, 57)', 'purple.50')}>
          <Heading  fontSize="2xl" mt="3" >
            User Agreement
          </Heading>
          <Text mt="4" >
            These terms of use (Terms) constitute a legally binding agreement
            between you and the company regarding your use of the website i.e.
            www.taskeye.com.ltd (&apos;taskeye.com&apos;) and it&apos;s mobile applications offered by the
            company including but not limited to delivery of content via the
            Site, any mobile or internet connected device or otherwise (the &apos;the
            Service&apos;). By accessing the site or service and/or by clicking &apos;I
            agree&apos;, you agree to be bound by these terms. “User” or “You” means
            any person who accesses or avails this site of the company for the
            purpose of hosting, publishing, sharing, transacting, displaying, or
            uploading information or views and includes other persons jointly
            participating in using the site of the company. You hereby represent
            and warrant to the company that you are at least eighteen (18) years
            of age or above and are capable of entering, performing, and
            adhering to these Terms and that you agree to be bound by the
            following terms and conditions. While individuals under the age of
            18 may utilize taskeye.com services, they shall do so only with the
            involvement & guidance of their parents and/or legal guardians,
            under such Parent / Legal guardian’s registered account. You agree
            to register prior to uploading any content and/or comment and any
            other use or services of this site and provide your details
            including but not limited to complete name, age, email address,
            residential address, and contact number.
          </Text>
          {/* Repeat the pattern for each section of your terms of service */}
          
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Changes
          </Heading>
          <Text mt="4" >
            GetMarkk Global Tech Pvt Ltd (“GGTPL”) reserves the right to
            suspend/cancel, or discontinue any or all channels, products, or
            services at any time without notice, make modifications and
            alterations in any or all of the content, products, and services
            contained on the site without prior notice.
          </Text>
          {/* Repeat for other sections */}
          
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Charges
          </Heading>
          <Text mt="4" >
            GGTPL reserves the right to charge subscription and/or membership
            fees from a user, by giving reasonable prior notice, in respect of
            any product, service, or any other aspect of this site.
          </Text>
          {/* Continue converting the rest of the document in a similar fashion */}
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Copyright and Trademarks
          </Heading>
          <Text mt="4" >
            Unless otherwise stated, copyright and all intellectual property
            rights in all material presented on the site and mobile apps
            (including but not limited to text, audio, video, or graphical
            images), trademarks, and logos appearing on this site are the
            property of Get taskeye.com India pvt ltd, its parent, affiliates and
            associates and are protected under applicable Indian laws. You agree
            not to use any framing techniques to enclose any trademark or logo
            or other proprietary information of GGTPL; or remove, conceal or
            obliterate any copyright or other proprietary notice or any
            credit-line or date-line on other mark or source identifier included
            on the site/service, including without limitation, the size, color,
            location or style of all proprietary marks. Any infringement shall
            be vigorously defended and pursued to the fullest extent permitted
            by law.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Sharing of Personal Information
          </Heading>
          <Text >
            For certain services offered by the partner businesses signup via
            email is required. To sign up for these services you have to open an
            account by completing the signing up process (i.e. by providing us
            with current, complete and accurate information as prompted by the
            applicable registration form). The verification of the email & the
            mobile number will be completed by providing a one time
            password(OTP). By Signing Up, you agree to the following terms in
            addition to any other specific terms which shall be posted at an
            appropriate location of the Site. Each sign-up is for a single
            individual user only. To access these services, you will be asked to
            enter your individual Email Address or mobile number, as chosen by
            you during your signing up. Therefore, we do not permit any of the
            following:-
          </Text>
          <UnorderedList spacing={3} mt="4" >
            <ListItem>
                Any other person sharing your account and password;
            </ListItem>
            <ListItem>
                Any part of the site being cached in proxy servers and accessed
                by individuals who have not registered with taskeye.com as users of the
                site; or
            </ListItem>
            <ListItem>
                Access through a single account and being made available to
                multiple users on a network.
            </ListItem>
          </UnorderedList>
          <Text >
          If taskeye.com reasonably believes that an account is being used/misused in
            any manner, taskeye.com shall reserve the right to cancel access rights
            immediately without notice, and block access to all users from that
            IP address.
          </Text> 
          <Text >
          Furthermore, you are entirely responsible for any and all activities
            that occur under your account. You agree to notify taskeye.com immediately
            of any unauthorized use of your account or any other breach of
            security. taskeye.com will not be liable for any loss that you may incur as
            a result of someone else using your account. However, you could be
            held liable for losses incurred by GGTPL/taskeye.com or another party due
            to someone else using your account.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Use of Profile Data
          </Heading> 
          <Text mt="4" >
            We may use your personal information to provide the services you
            request. However, to the extent we use your personal information to
            market to you, we will also provide you the ability to opt out of
            such uses. The personal information shall be used by us to resolve
            disputes; troubleshoot problems; help/promote a safe service;
            measure consumer interest in our products and services, inform you
            about online and offline offers, products, services, and updates;
            customize your experience; detect and protect us against error,
            fraud, and other criminal activity; enforce our terms and
            conditions; and as otherwise described to you at the time of
            collection. In our efforts to continually improve our products and
            services, we collect and analyze demographic and profile data about
            our user&apos;s activity on our mobile application.
          </Text>  
          <Text >
          We may occasionally ask you to engage in factual or interest-based
            surveys. These surveys may ask you for more information about your
            interest and demographic information (like favourite clothing brand,
            or preferred cuisines). The data so collected through surveys is
            solely to enhance your experience in the usage of the mobile
            application, providing you with content that we think you might be
            interested in, and displaying content according to your preferences.
            It is however clarified that filling in such surveys is optional.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            No unlawful or prohibited use
          </Heading> 
          <Text mt="4" >
          As a condition of your use of the services, you will not use the
            Services for any purpose that is unlawful or prohibited by these
            terms, conditions, and notices. You may not use the services in any
            manner that could damage, disable, overburden, or impair any of our
            servers, or the network(s) connected to any GGTPL server, or
            interfere with any other party&apos;s use and enjoyment of any Services.
            You may not attempt to gain unauthorized access to any Services,
            other accounts, computer systems, or any of the services, through
            hacking, OTP mining, or any other means. You may not obtain or
            attempt to obtain any materials or information through any means not
            intentionally made available through the Services.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Exchange of Information & other Community Services
          </Heading> 
          <Text mt="4" >
            You represent that you are a responsible adult (18 years or above)
            and/or otherwise capable of entering into and performing legal
            agreements, and that you agree to be bound by the following terms
            and conditions. While individuals under the age of 18 may utilize
            the service, they may do so only with the involvement of a parent or
            legal guardian, under such person ́s account and otherwise subject to
            these Terms. In certain jurisdictions, the age of majority may be
            older than 18, in which case you must satisfy that age in order to
            become a member / utilize the site or service and you shall be
            solely responsible for all Content/Images that you upload post or
            otherwise transmit.
          </Text>
          <Text >
          taskeye.com endeavors to provide a web platform for various community
            interactions for persons to interact and exchange views with each
            other. The content posted on such services is by the general public
            therefore the accuracy, integrity, or quality of such content cannot
            be guaranteed. You understand that using such services may expose
            you to objectionable matters. By using the facility of review/rating
            and other community services you agree not to upload, post, or
            otherwise transmit any matter or views, which are, defamatory,
            abusive, pervasive, obscene, invasive of another&apos;s privacy, hateful,
            or racially, ethnically or otherwise objectionable. You may also not
            indulge in cyber stalking, become or create a nuisance for any
            visitor/user of the site.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Prohibited Activities
          </Heading> 
          <Text mt="4" >
          The User shall not host, display, upload, modify, publish, transmit,
            store, update or share any information that,
          </Text> 
          <UnorderedList spacing={3} mt="4" >
            <ListItem>
            belongs to another person and to which the user does not have
                any right;
            </ListItem>
            <ListItem>
            is defamatory, obscene, pornographic, paedophilic, invasive of
                another’s privacy, including bodily privacy, insulting or
                harassing on the basis of gender, libellous, racially or
                ethnically
            </ListItem>
            <ListItem>
            objectionable, relating or encouraging money laundering or
                gambling, or otherwise inconsistent with or contrary to the laws
                in force;
            </ListItem>
            <ListItem>
            is harmful to a child;
            </ListItem>
            <ListItem>
            infringes any patent, trademark, copyright, or other proprietary
                rights,
            </ListItem>
            <ListItem>
            violates any law for the time being in force;
            </ListItem>
            <ListItem>
                deceives or misleads the addressee about the origin of the
                message or knowingly and intentionally communicates any
                information which is patently false or misleading in nature but
                may reasonably be perceived as a fact;
            </ListItem>
            <ListItem>
                impersonates another person;
            </ListItem>
            <ListItem>
                threatens the unity, integrity, defense, security or sovereignty
                of India, friendly relations with foreign States, or public
                order, or causes incitement to the commission of any cognizable
                offense or prevents investigation of any offense or is insulting
                other nation;
            </ListItem>
            <ListItem>
                contains a software virus or any other computer code, file, or
                program designed to interrupt, destroy or limit the
                functionality of any computer resource;
            </ListItem>
            <ListItem>
                is patently false and untrue, and is written or published in any
                form, with the intent to mislead or harass a person, entity, or
                agency for financial gain or to cause any injury to any person
            </ListItem>
          </UnorderedList> 
          <Text >
          Any Content and or comment uploaded by you, shall be subject to
            relevant Indian laws and may be disabled, or may be subject to
            investigation under appropriate laws. Furthermore, if you are found
            to be in non-compliance with the laws and regulations, these terms,
            or the privacy policy of the site, the Company shall have the right
            to immediately terminate/block your access and usage of the site and
            the Company shall have the right to immediately remove any
            non-compliant Content and or comment, uploaded by you and shall
            further have the right to take recourse to such remedies as would be
            available to the Company under the applicable laws.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Limitation of Liability
          </Heading> 
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            User Conduct and Obligations
          </Heading> 
          <Text mt="4" >
            You hereby agree and assure taskeye.com that the site/Services shall be
            used for lawful purposes only and that you will not violate laws,
            regulations, ordinances, or other such requirements of any
            applicable Central, State, or local government or any other
            international laws. You further concur that you will not, through
            Site/Mobile/Service:
          </Text>
          <UnorderedList spacing={3} mt="4" >
            <ListItem>
                post, distribute, or otherwise make available or transmit any
                software or other computer files that contain a virus trojan
                horses, time bombs, bots, botnets, malicious content, content
                theft, data manipulation, threats, or any other harmful programs
                or elements or component;
            </ListItem>
            <ListItem>
                delete from the Site any legal notices, disclaimers, or
                proprietary notices such as copyright or trademark symbols, or
                modify any logos that you do not own or have express permission
                to modify;
            </ListItem>
            <ListItem>
                not use the site / Mobile / Service in any manner that could
                damage, disable, overburden, or impair and not to undertake any
                action which is harmful or potentially harmful to any GGTPL /
                taskeye.com&apos;s server, or the network(s), computer systems/resource
                connected to any taskeye.com’s server, or interfere with any other
                party&apos;s use and enjoyment of the site/Mobile/Service;
            </ListItem>
            <ListItem>
                obtain or attempt to obtain any materials or information through
                any means not intentionally made available through the
                site/Mobile/Service;
            </ListItem>
            <ListItem>
            engage in any activity that causes / may harm minors; or
            </ListItem>
            <ListItem>
            perform any activity which is likely to cause such harm;
            </ListItem>
            <ListItem>
                impersonate any person or entity, including, but not limited to,
                taskeye.com’s official, forum leader, guide or host, or falsely state
                or otherwise misrepresent your affiliation with a person or
                entity;
            </ListItem>
            <ListItem>
                take any action which encourages or consists of any threat of
                harm of any kind to any person or property;
            </ListItem>
            <ListItem>
                carry out any &apos;denial of service&apos; (DoS, DDoS) or any other
                harmful attacks on application or internet service or;
            </ListItem>
            <ListItem>
                use the Site/Mobile/Service for illegal purposes;
            </ListItem>
            <ListItem>
                disrupt, place unreasonable burdens or excessive loads on,
                interfere with or attempt to make or attempt any unauthorized
                access to any taskeye.com website or mobile apps.
            </ListItem>
            <ListItem>
                transmit through the Site or Mobile apps, any unlawful,
                harassing, libelous, abusive, threatening, harmful, vulgar,
                obscene, libelous, hateful, or racially, ethnically or otherwise
                objectionable material of any kind or nature. This includes
                text, graphics, video, programs or audio, etc.;
            </ListItem>
            <ListItem>
                collect or attempt to collect personally identifiable
                information of any person or entity without their express
                written consent and you shall maintain records of any such
                written consent throughout the terms of this agreement and for a
                period of 2 years thereafter;
            </ListItem>
            <ListItem>
                engage in antisocial, disruptive, or destructive acts, including
                &apos;flaming,&apos; &apos;spamming,&apos; &apos;flooding,&apos; &apos;trolling,&apos; and &apos;griefing&apos; as
                those terms are commonly understood and used on the Internet
            </ListItem>
            <ListItem>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any content transmitted through the site.
            </ListItem>
            <ListItem>
                upload, post, email, transmit or otherwise make available any
                unsolicited or unauthorised advertising, promotional materials,
                &apos;junk mail&apos;, &apos;spam&apos;, &apos;chain letters&apos;, &apos;pyramid schemes&apos;,
                duplicative messages or any other form of solicitation.
            </ListItem>
            <ListItem>
                encumber or suffer to exist any lien or security interest on the
                subject matter of this Agreement; or
            </ListItem>
            <ListItem>
                make any representation or warranty on behalf of GGTPL.
            </ListItem>
            <ListItem>
                You agree not to post, distribute, transmit or otherwise make
                available any data, text, message, computer file, or other
                material that infringes and/or violates any right of a third
                party or any domestic or international law, rule, or regulation,
                including but not limited to:
            </ListItem>
            <ListItem>
                infringement of any copyright, trademark, patent, trade secret,
                or other proprietary rights of any third party, including, but
                not limited to, the unauthorized copying of copyrighted
                material, the digitization and distribution of photographs from
                magazines, books, or other copyrighted sources, and the
                unauthorized transmittal of copyrighted software;
            </ListItem>
            <ListItem>
                right of privacy (specifically, you must not distribute another
                person&apos;s personal information of any kind without their express
                permission) or publicity;
            </ListItem>
            <ListItem>
                any confidentiality obligation. Unless otherwise permitted, you
                will: (i) display the Service on your site or Products in the
                exact form received by you, and not modify or edit any of the
                foregoing without taskeye.com&apos;s prior written consent; (ii) ensure that
                the fundamental meaning of the Service is not changed or
                distorted; (iii) comply with all applicable laws and all
                limitations and restrictions (if any) placed by taskeye.com on the use,
                display or distribution of any Service and (iv) not archive any
                of the Service for access by users at any future date after the
                Service has been removed from your Website or product
                accordingly. You acknowledge that the service must link and
                redirect to the appropriate taskeye.com&apos;s web page when a user clicks
                on the Service (e.g. a headline). You shall not display the
                Service in such a manner that does not allow for successful
                linking and redirection to, and delivery of, taskeye.com&apos;s Web page,
                nor may you frame any taskeye.com&apos;s Web page.
            </ListItem>
          </UnorderedList>  
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          No Controlling Spam Policy or Unsolicited E-mails
          </Heading> 
          <Text mt="4" >
             You will not use any communication tool or other means available on
            the site to transmit, directly or indirectly, any unsolicited bulk
            communications (including emails and instant messages). taskeye.com will
            not sell, lease or rent its e-mail subscriber lists to third
            parties. You may not harvest information about users of taskeye.com for the
            purpose of sending or facilitating the sending of unsolicited bulk
            communications. We may terminate your access or use of the service
            immediately, with or without any notice, and take any other legal
            action if you, or anyone using your access details to the site or
            Mobile apps, violates these terms. We may adopt any technical remedy
            (including any filtering technology or other measures) to prevent
            unsolicited bulk communications from entering, utilizing, or
            remaining within our computer or communication networks. Such
            filtering technology or other measures may block, either temporarily
            or permanently, some e-mails sent to you through taskeye.com&apos;s Websites.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Consent Template Registration
          </Heading> 
          <Text mt="4" >
          Once you are a registered customer of taskeye.com, your email address and
            other personally identifiable information may be used to send
            product updates and marketing communications. To opt-out from such
            messages, click on the Unsubscribe link on any such email you
            receive from us. We may still continue to use your email address to
            notify you of any major administrative or operational updates for
            customer services purposes, account information, billing, etc.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Disclaimer of Warranties and Liability
          </Heading> 
          <Text mt="4" >
          GGTPL/taskeye.com disclaims all warranties, express or implied, statutory
            or otherwise, as to the Services provided, including without
            limitation, the implied warranties of merchantability, fitness for a
            particular purpose, workmanlike effort, title, and non-infringement
            are disclaimed and excluded.
          </Text>
          <Text >
          taskeye.com and its parent, affiliates and associates shall not be liable,
            at any time for any, direct, indirect, punitive, incidental,
            special, consequential, damages (including, without limitation,
            damages for loss of business projects, damage to your computer
            system or damages for loss of profits, damages for loss of use, data
            or profits, arising out of or in any way connected with the use or
            performance of the taskeye.com sites/mobile/services, with the delay or
            inability to use the taskeye.com’s sites/mobile/services or related
            services, the provision of or failure to provide services, or for
            any information, software, products, services and related graphics
            obtained through the taskeye.com sites/mobile/services, or otherwise
            arising out of the use of the GGTPL sites/mobile/services) arising
            in contract, tort or otherwise from the use of or inability to use
            the site, or any of its contents, or from any act or omissions a
            result of using the site or any such contents or for any failure of
            performance, error, omission, interruption, deletion, defect, delay
            in operation or transmission, computer virus, communications line
            failure, theft or destruction or unauthorised access to, alteration
            of, or use of information contained on the site. No representations,
            warranties or guarantees whatsoever are made by taskeye.com as to the
            (accuracy, adequacy, reliability, completeness, suitability or
            applicability of the information to a particular situation; (b) that
            the service will be uninterrupted, timely, secure, or error-free;
            (c) the quality of any products, services, content, information, or
            other material purchased or obtained from the website or mobile apps
            will meet your expectations or requirements; or (d) any errors in
            the website or mobile apps will be corrected.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Links to Other sites
          </Heading> 
          <Text mt="4" >
            All the contents of this Site and Mobile apps are only for general
            information or use. They do not constitute advice and should not be
            relied upon in making (or refraining from making) any decision. Any
            specific advice or replies to queries in any part of the Site and
            Mobile apps is/are the personal opinion of such
            experts/consultants/persons and are not subscribed to by this Site
            or Mobile app. The information from or through this site is provided
            on an &apos;AS IS&apos; basis, and all warranties and conditions, expressed or
            implied of any kind, regarding any matter pertaining to any goods,
            service, or channel, including without Certain links on the Site
            lead to resources located on servers maintained by third parties,
            these sites of a third party(s) may contain taskeye.com&apos;s-logo, please
            understand that it is independent of taskeye.com, over whom taskeye.com has no
            control or connection, business or otherwise as these sites are
            external to taskeye.com. You agree and understand that by visiting such
            sites you are beyond taskeye.com&apos;s website. taskeye.com, therefore neither
            endorses nor offers any judgement or warranty and accepts no
            responsibility or liability for the authenticity, availability,
            suitability, reliability, and accuracy of the information, software,
            products, services, and related graphics contained, in any of the
            goods/services/or for any damage, loss or harm, direct or
            consequential or any violation of local or international laws that
            may be incurred by your visit and/or interactions/s on these
            site(s), as the same is provided on &apos;as is&apos; without warranty of any
            kind. taskeye.com gives no warranty and makes no representation whether
            expressed or implied, that the information contained in this site is
            error-free. taskeye.com shall not be responsible nor liable for any
            consequential damages arising on account of your relying on the
            contents of the advertisement. Before relying on the material, users
            should independently verify its relevance for their purposes and
            should obtain any appropriate professional advice.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Data Protection
          </Heading> 
          <Text mt="4" >
          Part of the Site contains advertising information or promotion
            material or other material submitted to taskeye.com by third parties.
            Responsibility for ensuring that material submitted for inclusion on
            site or Mobile apps complies with applicable International and
            National law is exclusively on the party providing the
            information/material. Your correspondence or business dealings with,
            or participation in promotions of, advertisers other than taskeye.com found
            on or through the Website and or Mobile apps, including payment and
            delivery of related goods or services, and any other terms,
            conditions, warranties, or representations associated with such
            dealings, are solely between You and such advertiser. taskeye.com will not
            be responsible or liable for any claim, error, omission, inaccuracy
            in advertising material, or any loss or damage of any sort incurred
            as the result of any such dealings or as the result of the presence
            of such non-taskeye.com advertisers on the Website and Mobile apps. taskeye.com
            reserves the right to omit, suspend or change the position of any
            advertising material submitted for insertion. Acceptance of
            advertisements on the site and Mobile apps will be subject to these
            terms and conditions.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Advertising Material
          </Heading> 
          <Text mt="4" >
          taskeye.com may send information and offer products and services to you
            from time to time. For further details relating to our policy
            relating to such offer please refer to our privacy statement.
            Notwithstanding the foregoing, taskeye.com reserves the right to disclose
            any information in response to / that it is required to be shared,
            disclosed, or make made available to any governmental,
            administrative, regulatory, or judicial authority under any law or
            regulation applicable to taskeye.com. Further, taskeye.com can and you authorize
            taskeye.com/GGTPL to disclose your name, street address, city, state, zip
            code, country, phone number, email, and company name to Intellectual
            Property right&apos;s owners, as we in our sole discretion believe
            necessary or appropriate in connection with an investigation of
            fraud, intellectual property infringement, piracy, or other unlawful
            activity.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Relationship
          </Heading> 
          <Text mt="4" >
          None of the provisions of the User Agreement shall be deemed to
            constitute a partnership or agency between you and taskeye.com and you
            shall have no authority to bind taskeye.com in any manner, whatsoever.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Force Majeure
          </Heading> 
          <Text mt="4" >
            GGTPL shall have no liability to you for any interruption or delay,
            to access the site or Mobile apps irrespective of the cause.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Indian Law
          </Heading> 
          <Text mt="4" >
            The Agreement shall be governed by the Laws of India. The Courts of
            law at Pune shall have exclusive jurisdiction over any disputes
            arising under this agreement.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Entire Agreement
          </Heading> 
          <Text mt="4" >
          These Terms of Service constitute the entire agreement between the
            parties with respect to the subject matter hereof and supersedes and
            replaces all prior or contemporaneous understandings or agreements,
            written or oral, regarding the such subject matter.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Right to remove inappropriate messages
          </Heading> 
          <Text mt="4" >
            We reserve the right to remove any messages we deem to be
            inappropriate, i.e, racist, sexist or threatening. Messages using
            inappropriate language will also be removed. We do not wish to be
            censored, but our forums must remain civilized and respect the
            rights of others.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Limited time to bring your claim
          </Heading> 
          <Text mt="4" >
            You and taskeye.com agree that any cause of action arising out of or
            related to taskeye.com’s websites or mobile apps, only, must commence
            within one (3) month after the cause of action accrues otherwise,
            such cause of action will be permanently barred.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Acceptance Policy of Privacy
          </Heading> 
          <Text mt="4" >
            By using taskeye.com’s sites and services, you signify your acceptance of
            this Privacy Statement. If you do not agree or are not comfortable
            with any policy described in this Privacy statement, your only
            remedy is to discontinue use of taskeye.com sites or mobile apps. We
            reserve the right, to modify this Privacy Statement at any time.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          General Terms
          </Heading> 
          <Text mt="4" >
            Rights and obligations under the Terms which by their nature should
            survive will remain in full effect after termination or expiration
            of the Terms.
          </Text>
          <Text >
          Any express waiver or failure to exercise promptly any right under
            the Terms will not create a continuing waiver or any expectation of
            non-enforcement.
          </Text>
          <Text >
             If any provision of the Terms is held invalid by any law or
            regulation of any government, or by any court or arbitrator, the
            parties agree that such provision will be replaced with a new
            provision that accomplishes the original business purpose, and the
            other provisions of the Terms will remain in full force and effect.
          </Text>
          <Text >
          taskeye.com’s role is limited to managing the web platform for the display
            of the services/offers/deals provided by the restaurants and third
            parties and other incidental services to facilitate the dining
            transactions between the restaurant and the diners. Accordingly,
            taskeye.com is merely an intermediary and is only a platform/facilitator
            where the brands or merchants and another service provider may offer
            its services/deals/offers and discounts for marketing purposes. The
            contract for service shall be a strictly bipartite contract between
            the such service provider and the diner. At no time shall taskeye.com have
            any obligations or liabilities in respect of such contract nor shall
            taskeye.com hold any rights, title, or interest in the services provided by
            such service provider. taskeye.com shall not be responsible for any
            unsatisfactory or delayed performance or any actions or inactions of
            the restaurants/third party service provider including deficiency in
            services provided by such restaurants and the service providers.
          </Text>
          <Text >
          taskeye.com may on request of any third party promote its products/services
            by issuing promotional offers/gift coupons/discount
            vouchers/pre-paid vouchers (“promotional offers”). It is clarified
            that such promotional offers received from taskeye.com are to be settled
            inter-se between the parties to such transaction and the respective
            third party promoting its service/product and warranties express or
            implied of any kind, regarding any matter pertaining thereto,
            including without limitation the implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement are disclaimed by GGTPL/taskeye.com.
          </Text>
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
          Notice of Copyright Infringement and Grievance Redressal Mechanism:
          </Heading> 
          <Text mt="4" >
          taskeye.com is not liable for any infringement of copyright arising out of
            materials posted on or transmitted through the site or mobile apps,
            or items advertised on the site or mobile apps, by end users or any
            other third parties.
          </Text>
          <Text >
          Redressal Mechanism: Any complaints, abuse, or concerns with regards
            to content and or comment or breach of these terms shall be
            immediately informed to the designated Grievance Officer as
            mentioned below in writing or through email signed with the
            electronic signature to grievance@taskeye.com.ltd or call on
            +91-9834162784.
          </Text>
          <Text >
            Grievance Redressal Officer
          </Text>
          <Text >
          Phone No: +918237098983
        Office Address: FL­C­901 , The Latitude, New Heaven Park , Kondhwa , SN­18/6 ,
        NIBM Road Kondhwa , PUNE , Pune City , Pune , Maharashtra , 411048
          </Text>
          <Text >
          We request you to please provide the following information in your
            complaint:-
          </Text>
          <UnorderedList spacing={3} mt="4" > 
            <ListItem>
                A physical or electronic signature of a person authorized to act
                on behalf of the copyright owner for the purposes of the
                complaint.
            </ListItem>
            <ListItem>
                Identification of the copyrighted work claimed to have been
                infringed.
            </ListItem>
            <ListItem>
                Identification of the material on our website that is claimed to
                be infringing or to be the subject of infringing activity.
            </ListItem>
            <ListItem>
                 The address, telephone number, or e-mail address of the
                complaining party.
            </ListItem>
            <ListItem>
                A statement that the complaining party has a good-faith belief
                that the use of the material in the manner complained of is not
                authorized by the copyright owner, its agent, or the law.
            </ListItem>
            <ListItem>
                A statement, under penalty of perjury, that the information in
                the notice of copyright infringement is accurate, and that the
                complaining party is authorized to act on behalf of the owner of
                the right that is allegedly infringed
            </ListItem>
          </UnorderedList>  
          <Heading  fontSize="2xl" mt="3" className="primary-text-700">
            Copyright Notice
          </Heading> 
          <Text mt="4" >
            Copyright © {dayjs().format('YYYY')}, taskeye.com (Cano Partners Property).{' '}
          </Text>
          <Text >
            All Rights Reserved. This disclaimer/ terms of service notification
            are subject to change without notice.
          </Text>
        </Box>
      </Box>
    </Container>
    </Flex>
  )
}

export default Terms
