import { QRCodeCanvas} from 'qrcode.react'
import PropTypes from 'prop-types';
import { Center, useColorModeValue, useToken } from '@chakra-ui/react'

const QRcode = ({ code , color}) => {
    const message = `/start ${code}`
    const number = import.meta.env.VITE_APP_WHATSAPP_NUMBER
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`
    const bgColor = useColorModeValue('gray.50', 'gray.800')
    const [modeColor] = useToken(
        // the key within the theme, in this case `theme.colors`
        'colors',
        // the subkey(s), resolving to `theme.colors.red.100`
        [bgColor],
        // a single fallback or fallback array matching the length of the previous arg
      )
    console.log(modeColor)  
    return (
        <Center>
        <QRCodeCanvas value={url} fgColor={color} bgColor={"#FFFFFF"} size={350} level={"L"} includeMargin={true}/>
        </Center>
    )
}

QRcode.propTypes = {
    code: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default QRcode