import { useMemo } from "react"
import { Skeleton, Heading } from "@chakra-ui/react"

export const useSkeletonLoader = (helper, columns, dataQuery) => {
    const emptyColumn = useMemo(
        () => [
        helper.accessor('Name', {
            header: 'Name',
            cell: info => info.getValue()
        })
    ], [helper])

    const tableData = useMemo(
        () => (dataQuery.isLoading ? {data: { rows: Array(10).fill({})}} : dataQuery.data?.rows?.length > 0 ? dataQuery : {data: { rows: Array(1).fill({})}}),
        [dataQuery]
    )
    const tableColumns = useMemo(
        () =>
        dataQuery.isLoading
            ? columns.map((column) => ({
                ...column,
                cell: <Skeleton height='20px' isLoaded={!dataQuery.isLoading}/>
              }))
            : dataQuery.data?.rows.length > 0 ? columns : emptyColumn.map((column) => ({
                ...column,
                cell: <Heading size='lg' >No results found!</Heading>
              })),
        [dataQuery.isLoading, dataQuery.data?.rows.length, columns, emptyColumn]
    )
    return { tableData, tableColumns }
}