import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  
  dialog: {
    _light: {
        color: 'gray.700',
        bg: 'gray.50',
      },
    _dark: {
      color: 'gray.100',
      bg: 'gray.700',
    }
  }
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle
})