
import { Link as ReactRouterLink } from 'react-router-dom'
import {
    Link as ChakraLink
} from '@chakra-ui/react'


import PropTypes from 'prop-types';

const WebLink = ({ children, to, redirectTo }) => {
 
 const toPath = redirectTo ? `${to}?redirectTo=${redirectTo}` : to   
 return (
        <ChakraLink as={ReactRouterLink} to={toPath}>
                {children}
        </ChakraLink>
 )
}

WebLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    redirectTo: PropTypes.string,
};

export default WebLink