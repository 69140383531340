import { MenuButton, MenuList, MenuItem, Button, Menu} from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import PropTypes from 'prop-types';


const BulkActions = ({bulkActions, handleBulkAction, enabled}) => {
    return (
        <Menu>
        <MenuButton as={Button}  
        variant={"brandPrimary"}
        isDisabled={!enabled} rightIcon={<ChevronDownIcon />}>
            Actions
        </MenuButton>
        <MenuList>
        {bulkActions.map((event, index) => (
            <MenuItem key={index} icon={event.icon}  onClick={(e) => handleBulkAction(e, event.action)}>{event.label}</MenuItem>
        ))}
        </MenuList>
        </Menu>
    )
}

BulkActions.propTypes = {
    bulkActions: PropTypes.array.isRequired,
    handleBulkAction: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired
};

export default BulkActions