
import {
    Link as ChakraLink
} from '@chakra-ui/react'


import PropTypes from 'prop-types';

const ExternalLink = ({ children, redirectTo }) => {
 const url = `${redirectTo}`
 return (
        <ChakraLink href={url} isExternal textDecoration={"none"}>
                {children}
        </ChakraLink>
 )
}

ExternalLink.propTypes = {
    children: PropTypes.node.isRequired,
    redirectTo: PropTypes.string.isRequired,
};

export default ExternalLink