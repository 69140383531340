import { Button,  FormControl, FormErrorMessage, FormLabel, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import PhoneNumberInput from '@components/input/PhoneNumberInput';
import { COUNTRIES, getTelCodeCountry } from "@components/input/countries";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';


const EditOrgModal = ({ isOpen, onClose, onUpdate, org }) => {
    const queryClient = useQueryClient()

    const [number, setNumber] = useState("")
    const [telCode, setTelCode] = useState("")
    const [country, setCountry] = useState(null)

    const countryOptions = COUNTRIES.map(({ name, iso }) => ({
        label: name,
        value: iso
    }));

    const onPhoneNumberChange = (telCode, number) => {
        console.log(`${telCode}${number}`)
        setNumber(number)
        setTelCode(telCode)
    }

    const values = {
        name: org.name,
        email: org.email,
    }
    
    const {
        handleSubmit,
        register,
        setError,
        clearErrors,
        formState: { errors, isSubmitting }
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone_number: "",
            department: "",
            calendar : [],
            events: [],
        },
        values
    })

    const createOrg = async (data) => {
        data.phone_number = `${telCode}${number}`
        data.country_code = `${telCode}`
        if (!data.phone_number) {
            setError('phone_number', {
                type: 'manual',
                message: 'Phone number is required'
            })
        } else {
            await onUpdate(data, org?.id)
            queryClient.invalidateQueries({ queryKey: ['org_team',  org?.id] })
            setNumber("")
            clearErrors()
            onClose()
        }
    }

    useEffect(() => {
        if (org?.id) {
            const phone_number = org?.phone_number
            const country_code = org?.country_code
            const phone = phone_number != "" ? phone_number.substring(country_code.length) : ""
            const countryCode = getTelCodeCountry(country_code)
            setTelCode(country_code)
            setNumber(phone)
            setCountry(countryCode)
            console.log(phone, countryCode)
        }
    },[org])
    return (
        <Modal closeOnOverlayClick={true} size={'xl'} onClose={onClose} isOpen={isOpen}  blockScrollOnMount={false}  scrollBehavior='inside'>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>{"Edit org"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <form onSubmit={handleSubmit(createOrg)}>  
                <Stack  spacing={5} direction="column">
                    <Heading size='md'>Org details</Heading>
                    <FormControl  isInvalid={errors.name && true}>
                        <FormLabel htmlFor='name'>Name</FormLabel>
                        <Input id="name" 
                            name="name"
                            type='text'
                            size='lg'
                            bg={'gray.100'}
                            {...register('name', {
                                required: 'Name is required'
                            })}
                        />
                        {errors.name && (
                        <FormErrorMessage>
                        Please enter org name
                        </FormErrorMessage>
                        )}      
                    </FormControl>  
                    <FormControl  isInvalid={errors.phone_number && true}>
                        <FormLabel htmlFor='phone_number'>Phone number</FormLabel>
                        <PhoneNumberInput
                                name="phone_number"
                                id="phone_number"
                                value={number}
                                country={country}
                                options={countryOptions}
                                onChange={(code, value) => onPhoneNumberChange(code, value)}      
                        />
                        {errors.phone_number && (
                        <FormErrorMessage>
                            Please enter a valid number
                        </FormErrorMessage>
                        )}      
                    </FormControl>
                    <FormControl  isInvalid={errors.email && true}>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <Input id="email" 
                            name="email"
                            type='text'
                            size='lg'
                            bg={'gray.100'}
                            {...register('email', {
                                required: 'Email is required'
                            })}
                        />
                        {errors.email && (
                        <FormErrorMessage>
                        Please enter a valid email
                        </FormErrorMessage>
                        )}      
                    </FormControl>  
                    <Button variant="brandPrimary"  size="lg" type="submit" isLoading={isSubmitting} loadingText="Editing org...">
                            Edit org
                    </Button>
                    </Stack>
                  </form>  
                </ModalBody>
            </ModalContent>
        </Modal>  
    )
}

EditOrgModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    org: PropTypes.object.isRequired
}
export default EditOrgModal